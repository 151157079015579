import React from 'react'
import { Card as CardComponent } from 'theme-ui'
import { Layout, Stack, Main, Sidebar } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Sticky from '@components/Sticky'
import Seo from '@widgets/Seo'
import Categories from '@widgets/Categories'
import Social from '@widgets/Social'
import Tags from '@widgets/Tags'
import NewsletterCompact from '@widgets/NewsletterCompact/NewsletterCompact'
import {
    PostHead,
    PostImage,
    PostBody,
    PostComments,
    PostCommentsFacebook,
    PostCommentsGraph,
    PostTagsShare,
    PostFooter
} from '@widgets/Post'
import { useBlogCategories } from '@helpers-blog'

const Post = ({
    data: { post, tagCategoryPosts, tagPosts, categoryPosts, previous, next },
    ...props
}) => {
    const relatedPosts = [
        ...(tagCategoryPosts ? tagCategoryPosts.nodes : []),
        ...(tagPosts ? tagPosts.nodes : []),
        ...(categoryPosts ? categoryPosts.nodes : [])
    ]
    const { pageContext: { services = {}, siteUrl } = {} } = props
    const categories = useBlogCategories()

    return (
        <Layout {...props}>
            <Seo {...post} siteUrl={siteUrl} />
            <Divider />
            <Stack effectProps={{ effect: 'fadeInDown' }}>
                <PostHead {...post} />
            </Stack>
            <Divider />
            <Stack effectProps={{ fraction: 0 }}>
                <Main>
                    <CardComponent variant='paper'>
                        <PostImage {...post} inCard />
                        <PostBody {...post} />
                        <PostTagsShare {...post} location={props.location} />
                        {services.disqus && <PostComments {...post} />}
                        {services.graphComment && <PostCommentsGraph {...post} />}
                        {services.facebookComment && (
                            <PostCommentsFacebook {...post} siteUrl={siteUrl} />
                        )}
                        <PostFooter {...{ previous, next }} />
                    </CardComponent>
                </Main>
                <Sidebar>
                    {services.mailchimp && (
                        <>
                            <NewsletterCompact />
                        </>
                    )}
                    <Divider />
                    <Social />
                    <Divider />
                    <Categories categories={categories} />
                    <Divider />
                    <Tags tags={post.tags} />
                    <Divider />
                    {post.category && (
                        <Sticky>
                            <CardList
                                title='Related Posts'
                                nodes={relatedPosts}
                                variant='horizontal-aside'
                                omitMedia
                                omitCategory
                                limit={6}
                                distinct
                                aside
                            />
                        </Sticky>
                    )}
                </Sidebar>
            </Stack>
        </Layout>
    )
}

export default Post
